import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=731c4323&"
import script from "./AppHeader.vue?vue&type=script&lang=js&"
export * from "./AppHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDialog: require('/srv/deploy/unlover/releases/20240604162334/components/AppDialog.vue').default})
